@import 'colour-scheme';

.reviews {
  padding: 4rem 0rem 0.8rem 0rem;
  color: #fafafa;
  background-color: $swift-blue;
  // border-top-left-radius: 1.5rem;
  // border-top-right-radius: 1.5rem;
  font-size: 0.9rem;
  text-align: center;
  position: relative;
  border-bottom-right-radius: 4rem;

  &-summary {
    font-size: 1.1rem;
    text-align: center;

    &__link {
      text-decoration: none;
      color: whitesmoke;

      &:hover {
        text-decoration: none;
        color: whitesmoke;
      }
    }
  }

  &-platform {
    font-size: 1.3rem;
    font-style: italic;
    margin-bottom: 0rem !important;

    &-wrapper {
      display: inline-block;
      min-width: 159px;
    }
  }

  &-reviewer {
    width: fit-content;
    font-size: 1.3rem;
  }

  &-review {
    &_text {
      font-style: italic;
      font-weight: 400;
      font-size: 0.95rem;
      margin: 0% 7%;
    }

    &_date {
      font-size: 0.8rem;
    }
  }

  &-carousel {
    min-height: 360px;
  }

  &-logo {
    &-wrapper {
      display: inline-flex;
      max-width: 70px;
      max-height: 70px;
      margin-right: .3rem;
    }

    @media (min-width: 845.1px) {
      &-wrapper {
        margin-right: 1rem;
      }
    }
  }

  &-wrapper {
    max-width: 438px;
    // margin-left: auto;
    // margin-right: auto;
  }
}

@media (max-width: 756.1px) {
  .reviews {
    text-align: left;

    &-summary {
      font-size: 1.1rem;
      text-align: left;
      margin-left: .7rem;
      margin-right: .7rem;
    }
  }
}

.star-ratings-css {
  unicode-bidi: bidi-override;
  color: #c5c5c5;
  font-size: 25px;
  margin: 0 auto;
  // position: relative;
  text-shadow: 0 1px 0 #a2a2a2;

  &--small {
    display: inline-flex;
    font-size: 17px;
    justify-content: center;
    // height: 13px;
    // padding: 0.5rem;
  }
}

.star-ratings-css::after {
  color: gold;
  content: '★★★★★';
  text-shadow: 0 1px 0 #ab5414;
  z-index: 1;
  display: block;
  top: -10px;
  overflow: hidden;
}

.section {
  h5 {
    color: $swift-blue
  }

  &-btn {
    color: #fafafa;
    font-size: 1rem;
    font-weight: 600;
  }

  &-col {
    // display:inline-block;
    // text-align: center !important;
    background-color: white;
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem;
    margin: 0 0.7rem;
    box-shadow: 2px 1px 6px 0px #a0a0a0;
    transition: transform .2s;

    &:hover {
      transform: scale(1.05);
    }

    @media (min-width: 992px) {
      &--fit-large {
        flex: 0 0 31%;
        max-width: 31%;
      }
    }

    @media (min-width: 1440px) {
      &--fit-large {
        flex: 0 0 31.6%;
        max-width: 31.6%;
      }
    }

    &_course {
      border-radius: .3rem;

      &:hover {
        transform: none !important;
      }

      &_image_wrapper {
        display: flex;
        height: 227px;
      }

      &_image-round--top {
        content-fit: cover;
        width: 100%;
        height: 100%;
        border-top-left-radius: .3rem !important;
        border-top-right-radius: .3rem !important;
      }

      &_desc {
        min-height: 200px;
        font-size: 1.03rem;

        dl {
          // width: 60%;
          // margin-left: auto;
          // margin-right: auto;
        }

        dt {
          float: left;
          font-size: 1.07rem;
          padding-bottom: .4rem;
          margin-right: .5rem;
        }

        dd:first-of-type {
          color: $swift-red;
          font-weight: 600;
        }

        dd {
          text-align: right;
        }

        &-info {
          padding: 0.7rem 0.3rem 0 0;
          align-content: center;
          margin-top: 0.5rem;
          border-top: solid 1px #adadad;
        }
      }

      &_link--btn {
        text-decoration: none;
        color: #fafafa !important;
      }

      &-title {

        &-wrapper {
          display: flex;
          min-height: 65px;
          align-items: center;
          justify-content: center;
          border-bottom: solid 1px #adadad;
          margin-bottom: 10px;
        }
      }

      &-dt {
        float: left;
      }
    }
  }

  &-image-round {
    &--top {
      border-top-left-radius: .8rem !important;
      border-top-right-radius: .8rem !important;
    }
  }

  &-inner_link:hover {
      text-decoration: underline;
    }
  
  a {
    text-decoration: none;
    color: #212529;
  }

  &-p_content {
    min-height: 210px;
  }

  @media (min-width: 767.1px) {
    &-title {
      min-height: 65px;
    }

    &-p_content {
      min-height: 220px;
    }
  }

  @media (min-width: 991.1px) {
    &-title {
      min-height: 65px;
    }

    &-p_content {
      min-height: 290px;
    }
  }

  @media (min-width: 1203.1px) {
    &-title {
      min-height: 0;
    }
  }

  @media (min-width: 1354.1px) {
    &-p_content {
      min-height: 220px;
    }
  }
}

.call-us {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  margin-left: .3rem;

  p {
    margin: auto;
  }
}

.carousel-control-next-icon {
  &--blue {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23005997' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
}

.carousel-control-prev-icon {
  &--blue {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23005997' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
}
