@import "colour-scheme";

.highlighted {
  @media (max-width: 1024px) {
    height: 350px;
  }

  @media (max-width: 849px) {
    height: 230px;
  }

  @media (max-width: 425px) {
    height: 225px;
  }

  @media (max-width: 320px) {
    height: 200px;
  }

  position: relative;
  color: #454545;
  width: 100%;
  height: 350px;
  background: rgb(250,250,250);
  background: -moz-linear-gradient(left,  rgba(250,250,250,1) 0%, #fafafa 15%, #fafafa 85%, rgba(250,250,250,1) 100%);
  background: -webkit-linear-gradient(left,  rgba(250,250,250,1) 0%, #fafafa 15%, #fafafa 85%,rgba(250,250,250,1) 100%);
  background: linear-gradient(to right,  rgba(250,250,250,1) 0%,#fafafa 15%,#fafafa 85%,rgba(250,250,250,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#fafafa',GradientType=1 );
  overflow: hidden;

  &-content {
    background: rgb(235, 235, 235);
    border-bottom-right-radius: 2rem;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  &-image-wrapper {
    @media (max-width: 1024px) {
      width: 535px;
      // left: -1px;
    }

    @media (max-width: 849px) {
      width: 400px;
    }

    @media (max-width: 425px) {
      width: 390px;
    }

    @media (max-width: 375px) {
      width: 350px;
      bottom: 20px !important;
    }

    @media (max-width: 320px) {
      width: 298px;
      bottom: 20px !important;
    }

    position: relative;
    float: right;
    width: 550px;
    bottom: 10px;
    left: -1px;
    overflow: hidden;

    &--large {
      width: 800px;
    }

    &--gear {
      bottom: 40px;
    }

    &--gear_test {
      bottom: 60px;
    }

    &--highway {
      bottom: 45px;
    }

    &--auto {
      bottom: 30px;
    }

    &--logo {
      @media (max-width: 849px) {
        width: 200px;
      }

      @media (max-width: 375px) {
        width: 180px;
        bottom: 10px !important;
      }

      width: 270px;
    }
  }

  &-title {
    @media (max-width: 1000px) {
      display: none;
    }

    @media (max-width: 1200px) {
      width: 350px;
      text-align: center;
    }

    display: flex;
    position: absolute;
    height: 310px;
    margin-left: 5rem;
    z-index: 1;
    align-items: center;
    justify-content: center;

    &_content {
      @media (min-width: 1001px) {
        display: none;
      }

      @media (max-width: 425px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      position: relative;
    }
  }

  &-bottom-partial {
    width: 90%;
    margin: 0 auto;
    background-color: #fafafa;
    border-radius: 30px;
  }
}

.highlighted.shadow-box.shadow-box--top-bot::before {
  bottom: 28px;
  top: 28px;
}

.wave {
  &_svg {
    @media (max-width: 1024px) {
      height: 360px;
    }

    @media (max-width: 849px) {
      height: 260px;
    }

    @media (max-width: 425px) {
      height: 225px;
    }

    @media (max-width: 320px) {
      height: 200px;
    }

    width: 100%;
    height: 400px;

    &--large {
      height: 400px;
    }

    &_wrapper {
      @media (max-width: 849px) {
        height: 250px;
      }

      display: inline-block;
      position: absolute;
      width: 100%;
      height: 300px;
      z-index: 2;

      &--large {
        height: 400px;
      }
    }
  }

  // position: absolute;
  // width: 100%;
  // height: 100%;
  stroke: none;
  fill: #ffffff;
}

.pages {
  &-content-container {
    position: relative;
    z-index: 3;
    bottom: 40px;
  }

  &_prices_wrapper {
    @media (max-width: 767px) {
      margin: 1rem .7rem;
    }
    background-color: #fafafa;
    box-shadow: 2px 1px 6px 0 #a0a0a0;
    // margin: 0 .7rem;
  }

  &-ordered-list {
    list-style: none;
    counter-reset: new-list-counter;

    li {
      counter-increment: new-list-counter;
      position: relative;
    }

    li::before {
      @media (max-width: 767.9px) {
        position: relative;
        left: -65px;
        top: 0;
      }

      font-size: 1.75rem;
      content: 'Step ' counter(new-list-counter) ':';
      color: $swift-blue;
      position: absolute;
      left: -97px;
      top: -5px;
    }

    &--small {
      li::before {
        @media (max-width: 767.9px) {
          position: relative;
          left: 0;
          top: 0;
        }

        font-size: 1.2rem;
        content: 'Step ' counter(new-list-counter) ':';
        color: $swift-blue;
        position: absolute;
        left: 0px;
        top: -3px;
      }
    }

    &_no-padding {
      &--small {
        @media (max-width: 425px) {
          padding-left: 0;
        }
      }
    }
  }
}

.iframe-wrapper {
  iframe {
    @media (max-width: 400px) {
      height: 250px;
    }

    @media (max-width: 768px) {
      max-height: 350px;
    }

    display: block;
    margin: 0 auto;
    width: 100%;;
    max-width: 800px;
  }
}

.contact_us {
  &-form_wrapper {
    width: 80%;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}

.coming_soon {
  min-height: 500px;
  text-align: center;

  &-title {
    display: flex;
    min-height: 400px;
    font-size: 3rem;
    font-weight: 700;
    color: lighten($color: $grey, $amount: 30);
    align-items: center;
    justify-content: center;
  }

  &-desc {
    font-size: 1.4rem;
    font-weight: 600;
    color: $grey;
  }
}

.grey-subheading {
  font-size: 1.4rem;
  font-weight: 700;
  color: $grey;
}

.pass_plus {
  &-image-wrapper {
    width: 64px;
    max-height: 64px;
    display: inline-flex;
  }

  &-module {
    &-item {
      min-height: 93px;
      font-size: 1.2rem;
      font-weight: 600;
      color: $grey;
    }
  }
}

.practical {
  &-image-wrapper {
    width: 64px;
    max-height: 64px;
    display: inline-flex;
  }

  &-module {
    &-item {
      min-height: 143px;
      font-size: 1.2rem;
      font-weight: 600;
      color: $grey;
    }
  }
}

.unavailable {
  font-size: .9rem;
  font-weight: 700;
}