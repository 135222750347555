@import "colour-scheme";

.info-banner {
  background-color: $darkeryellow;
  position: relative;

  &_btn {
    position: absolute;
    top: .3rem;
    right: 1rem;
    font-size: .9rem;
  }

  &_p {
    padding: .6rem 0rem;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;

    a {
      color: inherit;
      font-weight: 550;
    }
  }

  &_p:before {
    display: inline-block;
    position: absolute;
    background-image: url('../content/images/icons/info.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    content: '';
    width: 16px;
    height: 16px;
    left: 20px;
    top: 12px;
  }
}
