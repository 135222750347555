$animate: all 0.1s ease-in-out;

.navbar {
  &-logo {
    @media (max-width: 425px) {
      max-height: 2.5rem;
    }

    height: 56.9px;
    align-self: center;
  }

  &-custom {
    @media (max-width: 991px) {
      padding: .2rem 1rem;
    }

    background-color: $swift-blue;
    color: #fafafa;

    &_wrapper {
      text-align: center;
      background-color: $swift-blue;
      color: #fafafa;
      min-height: 52px;
    }
  }
}

.navbar-light .navbar-nav .nav-link-wrapper .nav-link {
  color: #fafafa;
  font-size: 1rem;
  font-weight: 800;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    text-decoration: none;
  }
}

.dropdown {
  &-menu {
    background-color: $swift-blue;
  }

  &-item {
    color: #fafafa;
  }
}



@media (max-width: 991px) {
  .navbar-light .navbar-nav .nav-link-wrapper .nav-link {
    max-width: fit-content;
    margin: .4rem;
  }
}

@media (min-width: 991.1px) {
  .nav-link-wrapper {
    .hover {
      margin: 0 auto;
      padding: 0;
      transition: $animate;
      position: relative;
      text-align: center;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: -16px;
        width: 0px;
        height: 3px;
        margin: 10px 0 0;
        transition: $animate;
        transition-duration: 0.45s;
        opacity: 0;
        background-color: darken($swift-red,5%);
        z-index: 2;
      }
      &.hover-outwards {
        &:before {
          left: 50%;
        }
        &:after {
          right: 50%;
        }
      }
    }
    &:hover {
      cursor: pointer;
      .hover {
        &:before,
        &:after {
          width: 100%;
          opacity: 1;
        }
        &.hover-outwards {
          &:before,
          &:after {
            width: 50%;
          }
        }
      }
    }
  }
}
