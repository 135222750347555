$swift-blue: #005997;
$swift-red: #e40b51;
$white-of1: #fafafa;
$white-of2: #eeeded;
$grey: #505050;
$cold-white: #f1f7ff;
$yellow-warning:#fff600;
$indianred: #cd5c5c;
$ornagyyellow: #faac33;
$darkeryellow: #fad933;
// e3e6eb
