@import 'colour-scheme';
@import 'custom-navbar';
@import 'home.scss';

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p + p {
  margin-top: 0;
}

strong {
  font-weight: 700;
}

hr {
  border-top: 1px solid #bbb;
  width: 90%;

  &.short {
    width: 40%;
  }
}

.container-fluid {
  padding: 0px;
}

.btn-primary {
  background-color: $swift-red;
  border-color: $swift-red;

  &:hover {
    background-color: lighten($color: $swift-red, $amount: 10) !important;
    border-color: lighten($color: $swift-red, $amount: 10) !important;
  }

  &:focus,
  &.focus {
    background-color: lighten($color: $swift-red, $amount: 6);
    border-color: lighten($color: $swift-red, $amount: 6);
  }

  &.disabled,
  &:disabled {
    background-color: lighten($color: $swift-red, $amount: 6);
    border-color: lighten($color: $swift-red, $amount: 6);
  }

  &:active, .active {
  background-color: lighten($color: $swift-red, $amount: 6);
    border-color: lighten($color: $swift-red, $amount: 6);
  }
}

.btn-info {
  background-color: $swift-blue;
  border-color: $swift-blue;

  &:hover {
    background-color: lighten($color: $swift-blue, $amount: 10) !important;
    border-color: lighten($color: $swift-blue, $amount: 10) !important;
  }

  &:active, .active {
  background-color: lighten($color: $swift-blue, $amount: 6);
    border-color: lighten($color: $swift-blue, $amount: 6);
  }
}

.equaly-spaced-elements {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.screen-width-container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 0%;
  padding-left: 0%;
}

.page-width-container {
  max-width: 1260px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 3%;
  padding-left: 3%;
}

@media (min-width: 768.1px) {
  .page-width-container {
    padding-right: 2%;
    padding-left: 2%;
  }
}

@media (min-width: 1440.1px) {
  .page-width-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.padding {
  &-bottom {
    &--half {
      padding-bottom: 0.5rem;
    }

    &--base {
      padding-bottom: 1rem;
    }

    &--double {
      padding-bottom: 1.5rem;
    }

    &--tripple {
      padding-bottom: 2rem;
    }

    &--max {
      padding-bottom: 2.5rem;
    }
  }

  &-top {
    &--half {
      padding-top: 0.5rem;
    }

    &--base {
      padding-top: 1rem;
    }

    &--double {
      padding-top: 1.5rem;
    }

    &--tripple {
      padding-top: 2rem;
    }

    &--max {
      padding-top: 2.5rem;
    }
  }

  &-left {
    &--footer-col {
      padding-left: 1.2rem;
    }

    &--base {
      padding-left: 1rem;
    }

    &--double {
      padding-left: 1.5rem;
    }
  }
}

.margin {
  &-top {
    &--half {
      margin-top: .5rem;
    }
    &--base {
      margin-top: 1rem;
    }

    &--double {
      margin-top: 1.5rem;
    }

    &--tripple {
      margin-top: 2rem;
    }

    &--max {
      margin-top: 2.5rem;
    }
  }

  &-bottom {
    &--none {
      margin-bottom: 0;
    }

    &--base {
      margin-bottom: 1rem;
    }

    &--double {
      margin-bottom: 1.5rem;

      &_lg {
        @media (max-width: 575px) {
          margin-bottom: 1.5rem;
        }
      }
    }

    &--tripple {
      margin-bottom: 2rem;
    }

    &--max {
      margin-bottom: 2.5rem;
    }
  }

  &-right {
    &--base {
      margin-right: .5rem;
    }
  }
}

.center-content {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.center-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 767.99px) {
  .shadow-box {
    &::before {
      position: absolute;
      content: "";
      left: 75px;
      right: 75px;
      background: #777;
      z-index: -1;
      -webkit-box-shadow: 0 0 30px 17px #486685;
      -moz-box-shadow: 0 0 30px 17px #486685;
      box-shadow: 0 0 30px 17px #486685;
      border-radius: 100px/20px;
    }

    &--bottom::before {
      bottom: 30px;
    }

    &--top::before {
      top: 25px
    }

    &--top-bot::before {
      bottom: 12px;
      top: 12px;
    }
  }
}

.header {
  &-wrapper {
    display: flex;
    max-height: 4rem;
  }

  &-div-wrapper {
    display: inline-flex;
  }

  &-content {
    &-div-wrapper {
      width: 100%;
      font-size: .9rem;
    }

    &-div {
      display: inline-block;
      height: 100%;
    }

    &-btn-div {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &-social_wrapper {
      display: inline-flex;
      height: 24px;
      width: 24px;
      margin: 0.6rem;
      vertical-align: bottom;

      &_grey_fill {
        // background-color: #030304;
        background-color: #282828;
        padding: 2px;
        border-radius: 4px;
      }

      &_blue_fill {
        background-color: $swift-blue;
      }
    }
  }
}

.home {
  &-heading {
    h1 {
      @media (max-width: 425px) {
        font-size: 1.7rem;
      }

      text-align: center;
      font-size: 2.1rem;
      padding-bottom: 0.2em;
      color: $grey;
      font-weight: 600;
    }

    h2 {
      letter-spacing: 1px;
    }

    h3 {
      color: $grey;
      text-align: center;
      padding-bottom: 0.5rem;
    }

    h4 {
      color: $grey;
      text-align: center;
      padding-bottom: 0.5rem;
    }

    h5 {
      font-weight: 550;
    }

    &--large {
      font-size: 2.3rem !important;
    }

    &--home {
      text-align: justify;
      font-size: 1.5rem;
    }

    &--white {
      color: #fafafa !important;
    }

    &--grey {
      color: $grey;
    }
  }

  &-highlight_list {
    @media (max-width: 425px) {
      padding-left: 2rem;
    }

    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      @media (max-width: 425px) {
        font-size: 1rem;
      }

      position: relative;
      padding-left: 1.5rem;
      padding-bottom: .5rem;
      max-width: 350px;
      text-align: left;
      font-size: 1.3rem;
      font-weight: 500;
      margin-left: auto;
      margin-right: auto;
      color: black;
    }

    li::after {
      @media (max-width: 425px) {
        font-size: 1.5rem;
      }

      content: '\2713\0020';
      position: absolute;
      left: -1px;
      top: -4px;
      color: $swift-red;
      font-size: 1.6rem;
      width: 25px;
      height: 25px;
    }

    &-wrapper {
      background-color: $cold-white;
      border-bottom-left-radius: 80px;
      border-top-right-radius: 80px;
    }
  }
}

.get-quote {
  &-wrapper {
    bottom: 0px;
    background-color: $swift-blue;
    color: #fafafa;
    border-bottom-right-radius: 80px;
    border-top-left-radius: 80px;

    .tel-link {
      @media (max-width: 425px) {
        font-size: 1.1rem;
      }

      display: inline-flex;
      color: #fafafa !important;
      position: relative;
      left: 13px;
    }

    .tel-link:after {
      display: inline-block;
      position: absolute;
      // content: '\2706';
      background-image: url('../content/images/icons/phone_1_white.svg');
      background-size: 20px 20px;
      background-repeat: no-repeat;
      content: '';
      width: 50px;
      height: 50px;
      left: -25px;
    }
  }

  &_btn {
    @media (max-width: 425px) {
      font-size: .8rem;
    }

    color: #fafafa;
    font-size: 1.1rem;
    font-weight: 600;

    &-wrapper {
      width: 100%;
      text-align: center;
    }

    &--small {
      @media (max-width: 425px) {
        font-size: .6rem;
      }

      font-size: .8rem;
    }
  }
}

.footer {
  &-wrapper {
    padding: 2rem 0rem 0.8rem 0rem;
    color: #fafafa;
    background-color: $swift-blue;
    font-size: 0.9rem;
  }

  &-social-link {
    background-color: $swift-blue;
    color: #fafafa;
    border: none;
    padding-top: .3rem;
    padding-bottom: .3rem;

    &-terms:hover {
      color: lighten($color: $swift-red, $amount: 10) !important;
    }
  }

  &-headings {
    font-weight: 800;
    padding-left: 1.2rem;
  }

  &-subheading {
    font-size: 1.1rem;
  }

  &-logo-image {
    max-height: 150px;
    margin-left: auto;
    margin-right: auto;
  }

  &-contact {
    color: #fafafa;
    font-size: 1rem;
    position: relative;
    margin-left: .4rem;
    margin-right: auto;
    margin-bottom: 1rem;
    padding-left: 1.2rem;
    padding-bottom: .5rem;
    text-align: left;

    &:hover {
      color: darken($color: #fafafa, $amount: 10);
    }

    &_icon {
      display: inline-flex;
      height: 16px;
      width: 16px;
      margin: 0 .5rem .3rem 0;
      vertical-align: bottom;
    }

    &_email::after {
      @media (max-width: 768.1px){
        top: 2px;
      }

      display: inline-block;
      position: absolute;
      // content: '\2706';
      background-image: url('../content/images/icons/envelope.svg');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      content: '';
      width: 16px;
      height: 16px;
      left: -5px;
      top: 0px;
    }

    &_phone:after {
      @media (max-width: 768.1px){
        top: 1px;
      }

      display: inline-block;
      position: absolute;
      // content: '\2706';
      background-image: url('../content/images/icons/phone_1_white.svg');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      content: '';
      width: 16px;
      height: 16px;
      left: -5px;
      top: 0px;
    }
  }

  &-tnc {
    p {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  &-social_media_wrapper {
    margin-left: -8px;
  }
}

.text {
  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }

  &-v_middle {
    vertical-align: middle !important;
  }

  &-v_top {
    vertical-align: top !important;
  }

  &-v_bottom {
    vertical-align: bottom !important;
  }
}

.content {
  &--center {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
}

.vbreak::after {
  content: "";
  position: absolute;
  top: 16px;
  bottom: 16px;
  right: 0;
  border-right: 0.09em solid #bbb;
}

.narrow_content {
  margin-right: auto;
  margin-left: auto;
  max-width: 85%;
}

.cookie {
  position: fixed;
  display: flex;
  bottom: 0;
  background-color: rgb(28, 28, 28);
  justify-content: space-around;
  width: 100%;
  z-index: 999;
  color: #fafafa;

  &_policy-link {
    color: lighten($color: $swift-red, $amount: 10);
    border: none;

    &:hover {
      color: lighten($color: $swift-red, $amount: 15) !important;
    }
  }

  &-btn {
    &_wrapper {
      vertical-align: middle;
      min-width: 110px;
    }

    &--acpt {
      width: 100%;
    }
  }


  &-p {
    padding-left: 10px;
  }
}

.tel {
  &-link {
    color: $grey;
    white-space: nowrap;
    font-weight: 600;

    &-font-size {
      font-size: 1.3rem;
    }

    &:hover {
      text-decoration: none;
      color: $swift-red !important;
    }
  }

  &-image_wrapper {
    display: inline-flex;
    width: 24px;
    height: 24px;
    margin-right: .3rem
  }
}

.no {
 &-padding {
    &--left {
      padding-left: 0px;
    }
    &--right {
      padding-right: 0px;
    }

    &--top {
      @media (max-width: 425px) {
        &_small {
          padding-top: 0 !important;
        }
      }

      @media (min-width: 992px) {
        &_large {
          padding-top: 0 !important;
        }
      }
    }
  }

  &-margin {
    &--left {
      margin-left: 0;

      @media (min-width: 992px) {
        &_large {
          margin-left: 0;
        }
      }
    }

    &--right {
      margin-right: 0;

      @media (min-width: 992px) {
        &_large {
          margin-right: 0;
        }
      }
    }

    &--top {
      margin-top: 0;

      @media (max-width: 425px) {
        &_small {
          margin-top: 0 !important;
        }
      }

      @media (min-width: 992px) {
        &_large {
          margin-top: 0 !important;
        }
      }
    }
  }
}

.hidden {
  &--xs {
    @media (max-width: 500px) {
      display: none;
    }
  }
}

.red {
  color: $swift-red !important;
}

.success {
  color: #4BB543;
  font-weight: 600;
}

.border_line {
  &--top {
    border-top: 1px solid #e7e7e9
  }

  &--bottom {
    border-bottom: 1px solid #e7e7e9
  }
}

.box-shadow {
  background-color: #fafafa;
  box-shadow: 2px 1px 6px 0 #a0a0a0;
}

.contact-us {
  &_desc {
    font-size: 1.03rem;
    width: 300px;

    dt {
      float: left;
      font-size: 1.04rem;
      padding-bottom: .4rem;
      margin-right: .5rem;
      color: $grey;
    }

    dd {
      text-align: right;
      color: lighten($color: $grey, $amount: 5);
    }
  }
}

.info {
  color: lighten($color: $grey, $amount: 5);
  font-size: .8rem;
}